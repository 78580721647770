<template>
  <div id="passagewayRecord-tab">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <el-input
                placeholder="请输入车牌号"
                v-model="filtrate.keyword"
                @keyup.enter.native="getList(1)"
            >
              <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
        </ul>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%"
          v-loading="loading"
      >
        <el-table-column show-overflow-tooltip prop="channelName" label="设备名称" align="center">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="channelType" label="设备类型" align="center">
        </el-table-column>
        <el-table-column label="车牌匹配1" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span :class="{differentPlateNum:!scope.row.isSamePlateNum}">{{ scope.row.plateNum }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌匹配2" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span :class="{differentPlateNum:!scope.row.isSamePlateNum}">{{ scope.row.plateExtNum }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="liftType" label="抬杆类型" align="center">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="recordStatus"
            label="停车状态"
            align="center"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createAt" label="时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            layout="prev, pager, next"
            background
            :total="total"
            :page-size="pageSize"
            :current-page.sync="page"
            @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filtrate:{
        keyword:""
      },
      tableData: [],
      loading: false,
      pageSize: 10,
      page: 1,
      total:0
    }
  },
  computed: {
    info() {
      return this.$store.state.GangTing.info
    },
    parkingLotId() {
      return this.$store.state.GangTing.parkingLotId
    }
  },
  watch: {
    info(data) {
      this.tableData.splice(this.tableData.length - 1, 1)
      data.recordInfo.createAt = this.$moment(data.recordInfo.createAt).format(
          "yyyy-MM-DD HH:mm:ss"
      );
      if (!data.recordInfo.plateNum || !data.recordInfo.plateExtNum) {
        data.recordInfo.isSamePlateNum = true
      } else {
        data.recordInfo.isSamePlateNum = data.recordInfo.plateNum === data.recordInfo.plateExtNum
      }
      this.tableData.unshift(data.recordInfo)
    },
    parkingLotId: {
      handler(id) {
        if(id){
          this.page = 1
          this.getList()
        }
      },
      immediate: true
    }
  },
  methods: {
    async getList(page=this.page) {
      try {
        this.loading = true;
        let res = await this.$http.post("/channel/list/record", {
          ...this.filtrate,
          pageNum: page,
          pageSize: this.pageSize,
          parkingLotId: this.parkingLotId
        });
        if (res.code === 0) {
          for (let item of res.data.deviceChannelRecords.list) {
            item.createAt = this.$moment(item.createAt).format(
                "yyyy-MM-DD HH:mm:ss"
            );
            if (!item.plateNum || !item.plateExtNum) {
              item.isSamePlateNum = true
            } else {
              item.isSamePlateNum = item.plateNum === item.plateExtNum
            }
          }
          this.tableData = res.data.deviceChannelRecords.list;
          this.total = res.data.deviceChannelRecords.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
#passagewayRecord-tab {

}
</style>
